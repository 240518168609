import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

/*---Components---*/
import Hexagon from './hexagonComponent';
import { LinkComponent } from './linkComponent';

/*---CVT---*/
import { CvtColorSchema, DeviceSizes } from '@conventic-web/internal.global';
import { Headline } from '@conventic-web/internal.cvt.data-display.headline';

type HexagonCardProps = {
  link: string;
  linkText?: string;
  graphic: any;
  alt: string;
  iconsize: any;
  length: string;
  headline: string;
  description?: string;
};

export const HexagonCard: React.FC<HexagonCardProps> = ({
  link,
  linkText,
  graphic,
  alt,
  iconsize,
  length,
  headline,
  description,
}: HexagonCardProps) => {
  const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    width: 20em;
    @media screen and (max-width: ${DeviceSizes.laptop}) {
      width: 12em;
    }
  `;

  const HexLink = styled(Link)`
    text-decoration: none;
    color: ${CvtColorSchema.fontDarkGray};
  `;
  const TextContainer = styled.div`
    width: 11em;
    font-weight: 300;
  `;

  return (
    <Container>
      <HexLink to={link}>
        <Hexagon graphic={graphic} alt={alt} iconsize={iconsize}>
          {length === 'short' ? <div>{headline}</div> : null}
        </Hexagon>
      </HexLink>
      {length === 'long' ? (
        <TextContainer>
          <Headline variant={'h4'} text={headline} />
          <div>
            {description}
            <br />
            <LinkComponent linkTo={link}>{linkText}</LinkComponent>
          </div>
        </TextContainer>
      ) : null}
    </Container>
  );
};
