import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SYSTEM_STRINGS from '../../utility/data/system/systemStrings.yaml';
import { useLocation } from '@reach/router';

/*---CVT---*/
import { Headline } from '@conventic-web/internal.cvt.data-display.headline';
import { CvtColorSchema, CvtBasicTheme } from '@conventic-web/internal.global';
import { BlockTop } from '@conventic-web/internal.cvt.layout.block-top';
import { BlockStandard } from '@conventic-web/internal.cvt.layout.block-standard';
import { HtmlContent } from '@conventic-web/internal.basic.html-content';

/*---Components---*/
import Layout from '../../components/skeleton_page/layout';
import Seo from '../../components/skeleton_page/seo';
import PageblockPhotoAndGreen from '../../components/pageBlockPhotoAndGreen';
import PageblockIconsFlex from '../../components/pageBlockIconsFlex';
import { HexagonCard } from '../../components/hexagonCard';

/*---Objects---*/
import { PaddingObj, DiagonalObj } from '../../global/sharedObjects';

import MarkdownIt from 'markdown-it';
const md = MarkdownIt({ html: true });

export const KompetenzfelderPageTemplate = ({
  title,
  subtitle,
  image,
  competenceOverview,
  competenceInnovationProjects,
  competenceValueChain,
}: {
  title: string;
  subtitle: string;
  image: any;
  competenceOverview: any;
  competenceInnovationProjects: any;
  competenceValueChain: any;
}) => {
  return (
    <section className="section section--gradient">
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h1
          style={{
            color: CvtColorSchema.bgDarkGray,
          }}
        >
          {title}
        </h1>
        <h3
          style={{
            color: CvtColorSchema.bgDarkGray,
          }}
        >
          {subtitle}
        </h3>
      </div>
      <hr />

      <h2>{competenceOverview.headline}</h2>
      <HtmlContent
        dangerouslySetInnerHTML={md.render(competenceOverview.content)}
        style={CvtBasicTheme.htmlContent.general}
      />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {competenceOverview.hexagonBlock.map((item: any, index: number) => {
          return (
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              key={'competenceOverview-' + index}
            >
              <div
                style={{
                  backgroundColor: CvtColorSchema.yellow,
                  width: '70px',
                  height: '70px',
                }}
              >
                <img
                  src={item.image}
                  alt={item.headline}
                  width="50px"
                  height="50px"
                />
              </div>
              <h2>{item.headline}</h2>
              <HtmlContent
                dangerouslySetInnerHTML={md.render(item.content)}
                style={CvtBasicTheme.htmlContent.general}
              />
            </div>
          );
        })}
      </div>
      <hr />
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!competenceInnovationProjects.image.childImageSharp
              ? competenceInnovationProjects.image.childImageSharp.fluid.src
              : competenceInnovationProjects.image
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div
          style={{
            backgroundColor: `rgba(${CvtColorSchema.greenTransparent})`,
            color: CvtColorSchema.fontBlack,
            height: '50%',
            width: '60%',
          }}
        >
          <h2>{competenceInnovationProjects.headline}</h2>
          <HtmlContent
            dangerouslySetInnerHTML={md.render(
              competenceInnovationProjects.content
            )}
            style={CvtBasicTheme.htmlContent.general}
          />
        </div>
      </div>
      <hr />
      <h2>{competenceValueChain.headline}</h2>
      <HtmlContent
        dangerouslySetInnerHTML={md.render(competenceValueChain.content)}
        style={CvtBasicTheme.htmlContent.general}
      />
      <hr />
    </section>
  );
};

KompetenzfelderPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  competenceOverview: PropTypes.shape({
    headline: PropTypes.string,
    content: PropTypes.string,
    hexagonBlock: PropTypes.array,
  }),
  competenceInnovationProjects: PropTypes.shape({
    headline: PropTypes.string,
    image: PropTypes.string,
    content: PropTypes.string,
  }),
  competenceValueChain: PropTypes.shape({
    headline: PropTypes.string,
    content: PropTypes.string,
  }),
};

const KompetenzfelderPage = ({ data }: { data: any }) => {
  const { frontmatter } = data.markdownRemark;
  const location = useLocation();
  const featuredImage = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.resize
    : null;

  return (
    <Layout>
      <Seo
        title={frontmatter.pageTitle}
        keywords={frontmatter.seoKeywords}
        description={frontmatter.pageDescription}
        image={featuredImage}
        pathname={location.pathname}
      />
      <BlockTop
        headline={frontmatter.title}
        subheadline={frontmatter.subtitle}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        bgImage={
          !frontmatter.image.childImageSharp &&
          frontmatter.image.extension === 'svg'
            ? frontmatter.image.publicURL
            : null
        }
        zIndex={8}
        padding={PaddingObj.PaddingStarter}
        color={CvtColorSchema.fontDarkGray}
      />

      <BlockStandard
        headline={frontmatter.competenceOverview.headline}
        diagonalAlignment={DiagonalObj.DiagonalLeftUp as any}
        color={CvtColorSchema.fontDarkGray}
        bgColor={CvtColorSchema.white}
        zIndex={7}
        padding={PaddingObj.PaddingIdle}
        textAlignment={CvtBasicTheme.align.right as any}
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.competenceOverview.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
        <PageblockIconsFlex margintop="1%">
          {frontmatter.competenceOverview.hexagonBlock.map(
            (item: any, index: number) => {
              return (
                <React.Fragment key={'competencOverview-' + index}>
                  <HexagonCard
                    link={item.link}
                    graphic={
                      !item.image.childImageSharp &&
                      item.image.extension === 'svg'
                        ? item.image.publicURL
                        : null
                    }
                    alt={item.headline}
                    iconsize={'6'}
                    length={'long'}
                    headline={item.headline}
                    description={item.content}
                    linkText={SYSTEM_STRINGS.GENERAL.WORDS.MORE}
                  />
                </React.Fragment>
              );
            }
          )}
        </PageblockIconsFlex>
      </BlockStandard>

      <BlockStandard
        bgImage={
          !frontmatter.competenceInnovationProjects.image.childImageSharp &&
          frontmatter.competenceInnovationProjects.image.extension === 'jpg'
            ? frontmatter.competenceInnovationProjects.image.publicURL
            : frontmatter.competenceInnovationProjects.image.publicURL
        }
        padding={PaddingObj.PaddingGreenDiv}
        diagonalAlignment={DiagonalObj.DiagonalLeftUp as any}
        zIndex={6}
        color={CvtColorSchema.white}
      >
        <PageblockPhotoAndGreen>
          <Headline
            variant="h2"
            text={frontmatter.competenceInnovationProjects.headline}
          />
          <HtmlContent
            dangerouslySetInnerHTML={md.render(
              frontmatter.competenceInnovationProjects.content
            )}
            style={CvtBasicTheme.htmlContent.general}
          />
        </PageblockPhotoAndGreen>
      </BlockStandard>

      <BlockStandard
        headline={frontmatter.competenceValueChain.headline}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        padding={PaddingObj.PaddingIdle}
        zIndex={5}
        bgColor={CvtColorSchema.white}
        color={CvtColorSchema.fontDarkGray}
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.competenceValueChain.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
      </BlockStandard>
    </Layout>
  );
};

KompetenzfelderPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default KompetenzfelderPage;

export const kompetenzfelderPageQuery = graphql`
  query KompetenzfelderPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        competenceOverview {
          headline
          content
          hexagonBlock {
            headline
            link
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
            content
          }
        }
        competenceInnovationProjects {
          headline
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
          content
        }
        competenceValueChain {
          headline
          content
        }
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
          extension
          publicURL
        }
        seoKeywords
        pageTitle
        pageDescription
        subtitle
        title
        featuredImage {
          childImageSharp {
            resize(width: 900) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`;
